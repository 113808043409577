/* Copyright Information: Created by BuyGroup */
.invoice_container{ 
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    h2{
        margin-bottom: 25px;
    }
    .invoice_header{
        border-bottom: 1px solid #c6313d;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    .invoice-head td {
        padding: 0 8px;
      }
      .container {
        padding-top:30px;
      }
      .invoice-body{
        background-color:transparent;
      }
      address{
        margin-top:15px;
      }    
}

.prod_order_content_action{
  width: 15%;
  padding-left: 15px;
}
/* Copyright Information: Created by BuyGroup */