/* Copyright Information: Created by BuyGroup */
.footer-light{
    background: #f8f8f8;
}
.footer-title{
    text-align: left;
}
.outofstock-error{
    color: red;
    padding: 10px;
}
.footer-theme2{
    .footer-contant{
        ul{
            li{
                display: block;
                text-align: left;
                padding: 0;
                margin-bottom: 10px;
            }
        }
        ul.inline-element{
            text-align: left;
            li{
                display: inline-block;
            }
            .download_icon{
                margin-right: 15px;
                img{
                    max-width: 150px;
                }
            }
        }
    }
}
.download_icon{
    margin-right: 15px;
    a{
        text-decoration: none;
    }
    img{
        max-width: 150px;
    }
    &:hover{
        a{
            &:before{
                content: none !important;
            }
        }
    }
}
.header_wrapper_navbar{
    background-color: #f8f8f8;
    // background-color: #c6313d;
}
header .main-navbar .nav-menu{
    vertical-align: middle;
}

.main_header {
    position: relative;
    padding: 15px 0px;
}
.account_head_icon p {
    margin-bottom: 0;
    padding-top: 5px;
    color: #999;
    font-size: 14px;
    font-weight: 500;
}
ul.nav-sub-childmenu {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.11) !important;
    padding: 0 !important;
}
.cart_icon span.cart_value {
    background: #c6313d;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    position: absolute;
    top: -8px;
    right: -8px;
    line-height: 20px;
    font-size: 13px;
}
.account_head_icon img {
    max-height: 22px;
}
.acc_icon_head {
    position: relative;
    display: inline-block;
    margin-right: 8px;
}
.account_head_icon a {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
}
.account_menus li a {
    color: #999;
    font-size: 14px;
    font-weight: 500;
}
.account_menus li {
    margin-left: 20px;
    position: relative;
}
.account_menus li:before {
    // content: '/';
}
.account_menus li:before {
    // content: '/';
    position: relative;
    left: -10px;
}
.account_menus li:first-child:before {
    content: none;
}
.search_btn {
    background: #f0e1e2;
}
.nav-search {
    position: relative;
    padding: 0px 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: static;
    float: none;
    -webkit-box: 1;
    -moz-box: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.logo {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: static;
    float: none;
}
.header_right {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: static;
    float: none;
}
.nav-search input[type="text"] {
    height: 45px;
    border: 1px solid #eee;
    box-shadow: none !important;
    font-size: 15px;
    outline: none !important;
}
.search_btn {
    background: #f0e1e2;
    width: 50px;
    color: #c6313d;
    box-shadow: none !important;
    font-size: 22px;
}
header .main-navbar .nav-menu > li{
    margin: 0 5px;
    a{
        font-size: 15px;
    }
}
.img-responsive{
    max-width: 100%;
}
.theme-card {
    display: flex;
    justify-content: space-between;
}
.theme-card.theme-card-icon {
    margin-bottom: 18px;
}
.product-wrapper-grid {
    .product {
        margin-bottom: 35px;
        box-shadow: 2px 6px 15px 0px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        .product-detail{
            h6{
                min-height: 63px;
                line-height: 20px;
                padding: 0 10px;
                overflow: hidden;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;  
                    margin-bottom: 10px;
            }
            h4{
                padding: 0 10px 20px 10px;
            }
        }
    }
}
.hidden {
    visibility: hidden;
}
.product-box.product-wrap {
    .cart-wrap{
        width: 100%;
        bottom: 0px;
        background-color: #ff4c3b;
        button{
            width: 100%;
            color: #fff;
            font-weight: bold;
            i{
                display: inline-block !important;
                color: #fff;  
            }
        }
    }
   
}
.main-navbar-cart{
    ul{
        li{
            position: relative;
            &:hover{
                .nav-submenu{
                    display: block;
                }
            }
        }
    }
    .nav-submenu{
        display: none;
        position: absolute;
        right: 0;
        min-width: 260px;
        box-shadow: 0 0 1px 0 #7c7878;
        padding: 0px;
        background: white;
        z-index: 9;
        li{
            padding: 10px 15px;
            border-bottom: 1px solid #eee;
            display: block;
            &:last-child{
                border-bottom: none;
            }
        }
    }
}
.main-navbar-cart{
    .media {
        display: flex;
        align-items: flex-start;
        img {
            max-height: 70px;
            max-width: 70px;
            height: auto;
        }
        .media-body {
            align-self: center;
            h4 {
                color: #333;
                span{
                    color: #777;
                    font-size: 16px;
                }
            }
        }
    }
    .close-circle {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        cursor: pointer;
        i {
            color: #a1a1a1;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
        }
    }
    .buttons.view-cart {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            font-size: 16px;
            color: #333;
            text-transform: capitalize;
            font-weight: 700;
            &:hover{
                color: #ff4c3b;
            }
        }
    }
    
}

.cart_listing.cart-section {
    tbody {
        tr {
            td{
                min-width: 1px;
            }
        }
        tr.disabled_row{
            opacity: 0.6;
            pointer-events: none;
            position: relative;
            &:after{
                content: "\f110";
                position: absolute;
                width: 100%;
                text-align: center;
                z-index: 9;
                color: red;
                left: 0;
                font: normal normal normal 25px/1 FontAwesome;
                text-rendering: auto;
                top: 19px;
                -webkit-animation: fa-spin 2s infinite linear;
                animation: fa-spin 2s infinite linear;
                
            }
        }
    }
}
.custom-loader {
    text-align: center;
    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        animation: .75s linear infinite spinner-border;
    }
}
.cart-table-remove-icon {
    cursor: pointer;
    font-size: 19px;
}

.cart-section {
    tfoot {
        tr {
            td{
                padding-right: 0;
                &:first-child{
                    width: 88%;
                }
            }
        }
    }
    .cart-buttons > div:last-child{
        padding-right: 15px;
    }
}
button.addtocart_button[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}
.addtocart_button {
    .custom-loader {
        display: inline-block;
        vertical-align: middle;
        .spinner-border{
            width: 1rem;
            height: 1rem;
        }
    }
}
.product-box {
    .img-wrapper {
        position: relative;
        min-height: 233px;
        background: #fff;
        border-bottom: 1px solid rgba(128, 125, 128, 0.16);
        .lable-block {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            img.img-responsive {
                object-fit: contain;
                max-height: 233px;
                margin: 0 auto;
                display: block;
                max-width: 100%;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                width: 100%;
                min-height: 233px;
            }
        }
    }
}
a.btn.btn-solid.button_disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.2;
}
.parent_slider {
    margin-bottom: 25px;
}
.react_slick_thumb_slider .slick-slide {
    padding: 0px 10px;
}
.thumb_slider_image {
    padding: 5px 15px;
    vertical-align: middle;
}
.main_slider_image>img {
    max-height: 340px;
    width: auto;
    margin: 0 auto;
    object-fit: contain;
}
.thumb_slider_image>img {
    max-height: 80px;
    height: 80px;
    width: auto;
    object-fit: contain;
    margin: 0 auto;
}
.img-responsive {
    max-width: 100%;
}
.react_slick_thumb_slider .slick-slide > div {
    border: 1px solid rgba(128, 128, 128,  0.1);
    cursor: pointer;
}
.react_slick_main_slider {
    background: rgba(0,0,0,.01);
}
.product_detail_category p, 
.product_detail_value p {
    color: #313131;
    font-size: 14px;
}

.parent_qty_div{
    .quantity-input {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: 3px;
      }
      .quantity-input:focus {
        background: #ff4c3b;
      }
      
      .quantity-input__modifier,
      .quantity-input__screen {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        outline: none;
      }
      
      .quantity-input__modifier {
        padding: 7px 5px;
        width: 3rem;
        font-size: 1.5rem;
        background: #f3f3f3;
        color: #888;
        border: 0 solid #dbdbdb;
        text-align: center;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
        cursor: pointer;
      }
      .quantity-input__modifier:hover {
        background: #ff4c3b;
        color: #ffffff;
      }
      .quantity-input__modifier--left {
        border-radius: 3px 0 0 3px;
      }
      .quantity-input__modifier--right {
        border-radius: 0 3px 3px 0;
      }
      
      .quantity-input__screen {
        width: 5rem;
        padding: 7px 5px;
        font-size: 1.5rem;
        border: 0;
        border-top: 0 solid #dbdbdb;
        border-bottom: 0 solid #dbdbdb;
        text-align: center;
      }
}

.react-autosuggest__suggestions-container {
    position: absolute;
    width: 100%;
    top: 51px;
    background: #fff;
    box-shadow: 2px 6px 15px 0px rgba(0, 0, 0, 0.12);
    z-index: 9;
    ul {
        li {
            border-bottom: 1px solid rgba(128, 128, 128, 0.1);
            padding: 7px 15px;
            font-size: 13px;
            color: #333;
            &:last-child{
                border-bottom: none;
            }
        }
    }
}
.nav-search{
    .input-group{
        display: block;
        input[type="text"]{
            padding-right: 70px;
        }
    }
    .input-group-append {
        margin-left: -1px;
        position: absolute;
        top: 0;
        right: 0;
        height: 45px;
    }
}


.profileTab_uline {
    background: #fff;
    position: relative;
    margin: 0;
    padding: 0;
    border-bottom: none;
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    li {
        width: 100%;
        a{
            border: none!important;
            text-transform: capitalize;
            color: #000;
            padding: 18px 15px;
            border-right: 2px solid transparent;
            border-radius: 0 !important;
            cursor: pointer;
            border-bottom: 1px solid #eee !important;
            &:hover{
                border: none;
                color: #ff4c3b !important;
                border-right: 2px solid #ff4c3b !important; 
            }
            &:focus{
                border: none;
                color: #ff4c3b !important;
                border-right: 2px solid #ff4c3b !important; 
            }
        }
        .active{
            background-color: transparent !important;
            border: none;
            color: #ff4c3b !important;
            border-right: 2px solid #ff4c3b !important; 
        }
    }
}

.profileTabContent {
    position: relative;
    padding-left: 50px;
    .tab_header_vs h3 {
        margin: 0;
        color: #000;
        font-size: 22px;
        font-weight: 600;
    }
    
}
.card_box_ul{
    .card_grid_box {
        position: relative;
        border: 1px solid #eee;
        margin-bottom: 10px;
        padding: 15px;
        background: #fff;
        width: 49%;
        .card_box_head {
            position: relative;
            padding-right: 100px;
            h5{
                margin-bottom: 3px;
                font-size: 18px;
            }
            .card_action_btn_vs {
                position: absolute;
                top: 0;
                right: 0;
                li i {
                    color: #999;
                    margin-left: 10px;
                    font-size: 18px;
                    &:hover{
                        color: #ed4837;
                    }
                }
            }
            
        }
        p{
            margin: 0;
            font-size: 15px;
        }
    }
}


label.err-msg {
    margin: 0;
    color: red;
    font-size: 11px;
    margin-top: 5px !important;
}
.add_card_popup input[type="radio"], .add_card_popup input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    width: auto;
    display: inline-block;
    line-height: 1;
    height: auto;
    margin-right: 5px;
}
.add_card_popup  input.form-control, .add_card_popup  select.form-control  {
    font-size: 14px;
}
.add_card_popup .modal-header {
    background: #f9f9f9;
    border: none;
}
.add_card_popup h5.modal-title {
    font-weight: 600;
}
.product_order_grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
.p_order_img {
    width: 15%;
}
.prod_order_content {
    width: 68%;
    margin-left: 2%;
    padding-right: 15px;
}
.product_order_grid .prod_order_content_action {
    width: 15%;
    padding-left: 0 !important;
    text-align: center;
}
span.order_date {
    display: inline-block;
    margin-bottom: 15px;
}
.prod_order_content h3 {
    color: #222;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
}
.prod_order_content ul li {
    margin-right: 30px;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
}
.prod_order_content ul li:before {
    content: "|";
    position: absolute;
    right: -15px;
    color: #ddd;
}
.prod_order_content h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-right: 30px;
}
.prod_order_content ul li:last-child:before {
    content: none;
}
.product_order_status {
    padding: 6px 8px;
    border-radius: 6px;
    min-width: 100px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
    margin-bottom: 15px;
}
.product_order_status.in_progress {
    background: rgba(198,49,61,.1);
    color: #c6313d;
}
.product_order_status.shipping {
    background: rgba(243,155,62,.1);
    color: #f39b3e;
}
.product_order_status.completed {
    background: rgba(68,150,40,.1);
    color: #449628;
}
.shipping_header_checkout {
    border-bottom: 1px solid #eee;
    margin-bottom: 25px;
    padding-bottom: 10px;
    position: relative;
}
.shipping_header_checkout h3 {
    margin: 0;
    color: #000;
    font-weight: 600;
}
.checkout-details {
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 40px;
    position: relative;
}
.order-box .qty li {
    display: flex;
    justify-content: space-between;
}
.form-control.disabled {
    background-color: #e9ecef;
    opacity: 1;
    height: 46px;
    padding: 0px 15px;
    line-height: 46px;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.checkout_card_list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.checkout_card_list ul li {
    width: 48%;
    background-color: #ffffff;
    margin-bottom: 20px;
    p{
        margin-bottom: 0;
    }
}

.checkout-details .shipping_header_checkout {
    border: none;
    padding-bottom: 15px;
    margin: 0;
}
.order-box .table-bordered thead th, .order-box .table-bordered thead td {
    border-bottom-width: 1px;
}

.inputGroup {
    background-color: #fff;
    display: block;
    margin:  0;
    position: relative;
  }
  .inputGroup label {
    padding: 12px 15px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3C454C;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;
    margin-bottom: 0;
    padding-right: 40px;
  }
  .inputGroup label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    background-color: #f0e1e2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }
  .inputGroup label:after {
    width: 24px;
    height: 24px;
    content: "";
    border: 2px solid #D1D7DC;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: -2px -1px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
  }
  .inputGroup input:checked ~ label {
    color: #fff;
  }
  .inputGroup input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
  }
  .inputGroup input:checked ~ label:after {
    background-color: #ed4837;
    border-color: #ed4837;
  }
  .inputGroup input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    margin: 0;
  }



  .login-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(246, 246, 246, 0.60);
    margin-left: -15px;
    margin-right: -15px;
    .container{
        max-width: 400px;
        h3{
            margin-bottom: 6px;
            font-size: 30px;
            letter-spacing: 1px;
        }
        .lead_login_txt{
            font-size: 14px;
            a{
                text-decoration: underline;
                font-size: 14px;
                color: #c6313d;
            }
        }
        .theme-card{
            background-color: #fff;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            border-radius: 8px;
            border:none;
            display: block;
            .auth-form {
                .form-control {
                    border-radius: 3px;
                    padding: 0px 15px;
                    border: 1px solid #eaeaea;
                    font-size: 14px;
                    height: 44px;
                    line-height: 44px;
                }
                .input-feedback {
                    color: red;
                    font-size: 11px;
                    margin-top: 4px;
                }
                .btn {
                    background: #c6313d;
                    border-color: #c6313d;
                    height: 44px;
                    min-width: 130px;
                    border-radius: 3px;
                    padding: 0px 15px;
                    line-height: 42px;
                }
                .forgot-link {
                    text-align: right;
                    a {
                       
                        text-decoration: underline;
                        font-size: 14px;
                        color: #c6313d;
                    }
                }
                .my_account_footer > .col-md-6 {
                    width: 50%;
                }
            }
            
        }
    }
}
.add_card_popup .btn {
    min-width: 110px;
}
.pr-tab .myorder .card-body{
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    border: none;
}
.profile_update_form_vs {
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.11);
    padding: 30px;
    border-radius: 5px;
  }
.dashboard_card_listing{
    box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    padding: 30px;
    background: #fff;
}
.register-page .container {
    max-width: 700px;
    .theme-card .theme-form input {
        border-color: #dddddd;
        font-size: 1rem;
        padding: 0px 15px;
        margin-bottom: 30px;
        height: 46px;
    }
    .css-yk16xz-control, .css-g1d714-ValueContainer{
        height: 46px;
        border-radius: 0;
        .css-g1d714-ValueContainer {
            padding: 0px 8px;
            height: 46px;
        }
    }
    .css-1wa3eu0-placeholder{
            top: 15px;
            transform: inherit;
        
    }
    .css-1uccc91-singleValue {
            top: 20px;
            transform: inherit;
    }
    

}
span.order_date {
    label{
        margin-bottom: 0;
        font-weight: bold;
    }
    p{
        margin-bottom: 0;
    }
}


header .main-navbar .nav-menu > li .nav-submenu {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.11);
    padding: 0;
    li{
        a{
            padding: 15px;
            &:before{
                content:none;
            }
            &:hover{
                color:#c6313d;
            }
        }
    }
}
.onhover-dropdown .onhover-show-div {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.11);
    padding: 0;
    top: 100%;
    li{
        margin-left: 0;
        display: block;
        a{
            display: block;
            padding: 15px;
            border-bottom: 1px solid #eee;
            &:hover{
                color:#c6313d;
            }
        }
    }
}
.product-box .img-wrapper {
    min-height: 1px;
    .lable-block {
        position: relative;
        top: 0;
        transform: inherit;
        width: 100%;
    }
}
.collection-collapse-block .collapse-block-title{
    font-size: 15px;
    margin: 15px 0 15px 0;
}
.collection-filter {
    max-width: 20%;
     h5 {
    margin: 0;
    padding: 0;
    font-size: 15px;
     }
}

.zip-code-format{
    padding: 4% -1px;
}

.main-container .product-grid-view{
    flex: 0 0 80%;
    max-width: 80%;
}
.collection-filter-block {
    margin-top: 10px;
    ul.category-list {
        a{
            font-size: 14px;
        }
    }
}
.input-range__label-container {
    font-family: Lato, sans-serif;
}
.collection-filter button[type="submit"] {
    background: none;
    color: #c6313d;
    border: none;
    text-decoration: underline;
    padding: 0;
    font-size: 13px;
}
.account_menus li a span {
    padding-top: 5px;
    display: inline-block;
}
.account_menus li a i{
    font-size: 24px;
}
.account_menus li a{
    display: flex;
    align-items: center;
}

.contact_page h3, .terms_condition h3, .faq h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #000;
}
.contact_page p, .terms_condition p, .faq p {
    font-size: 14px;
}
.faq h5 {
    margin-top: 20px;
    font-weight: 600;
}
.show_mobile{
    display: none;
}
.checkout_detail_column {
    position: relative;
    padding-left: 50px;
}
.input-feedback, .err-msg {
    margin-bottom: 10px;
    color: red !important;
    font-size: 11px !important;
    margin-top: 5px !important;
}
.register-page .container .theme-card .theme-form input {
    margin-bottom: 0;
}
.reset_password .forgot-link {
    text-align: right;
}
.reset_password .forgot-link a {
    text-decoration: underline;
    font-size: 14px;
    color: #c6313d;
}
.reset_password .btn {
    background: #c6313d;
    border-color: #c6313d;
    height: 44px;
    min-width: 130px;
    border-radius: 3px;
    padding: 0px 15px;
    line-height: 42px;
}
.profile_update_form_vs .row.modal-body {
    padding: 0;
}
.profile_update_form_vs .col-form-label {
    padding-top: 0;
}
.profile_update_form_vs input.form-control {
    height: 46px;
    padding: 0px 15px;
    line-height: 46px;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.profile_update_form_vs .css-yk16xz-control {
    height: 46px;
}
.profile_update_form_vs .modal-footer{
    border-top: none;  
}

.min-height{
    min-height: 50vh;
}


@media (max-width: 1199px){

.overlay123456 {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 95;
}  

.min-height{
    min-height: 50vh;
}

.header_wrap {
    flex-wrap: wrap;
    position: relative;
}
.nav-search.desktop_search {
    order: 3;
    width: 100%;
    padding: 0;
    margin-top: 20px;
}
.account_menus {
    margin-right: 50px;
}
.main-nav-center .toggle-nav {
    position: absolute;
    z-index: 99;
    right: 15px;
    top: -121px;
    padding: 0;
}
header{
    background: #fbfbfb;
}
.checkout-details {
    padding: 25px 20px;
}
.checkout_detail_column {
    position: relative;
    padding-left: 15px;
}
.product_order_status {
    min-width: 1px;
}
header .main-navbar .nav-menu > li {
    margin: 0;
}
header .main-navbar .nav-menu > li a .sub-arrow {
    right: 0;
    display: none;
}
header .main-navbar .nav-menu > li .nav-submenu {
    padding: 0;
    display: block;
    box-shadow: none;
    padding-left: 20px;
}
header .main-navbar .nav-menu > li .nav-submenu li a i {
    display: none;
}
header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    display: block;
    position: relative;
    right: 0;
    width: 100%;
    padding: 0;
    background: transparent;
    box-shadow: none;
    padding-left: 20px;
    top: 0px !important; 
}
ul.nav-sub-childmenu {
    box-shadow: none !important;
    padding: 0 !important;
    padding-left: 20px !important;
}
header .main-navbar .nav-menu > li .nav-submenu li > a:before {
    content: "-";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
}
}

@media (max-width: 992px){

.display-flex{
    display: flex;
}

.main-container .product-grid-view{
    flex: 0 0 100%;
    max-width: 100%;
}

.table-responsive {
    overflow-y: auto;
    max-height: 300px; /* Adjust the maximum height as needed */
}

.responsive-table-1 {
overflow-y:auto;
display:table;
width: 100%;
table-layout:fixed;
}



.product-box.product-wrap .cart-wrap {
    padding: 8px 0px;
    position: relative;
}
.profileTabContent {
    position: relative;
    padding-left: 0;
}
.card_box_ul .card_grid_box {
    width: 100%;
}
.profileTab_uline {
    margin-bottom: 35px;
}
.container, .container-sm {
    max-width: 100%;
}

}


@media(max-width: 767px){

    .footer-new{
        display: block !important;
    }
    
    .footer-center{
        display: block !important;
    }

.col-grid-box {
    max-width: 100%;
    flex: 0 0 100%;
}
.p_order_img {
    width: 100%;
    margin-bottom: 15px;
}
.prod_order_content {
    width: 100%;
    margin-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
}
.product_order_grid .prod_order_content_action {
    width: 100%;
    padding-left: 0 !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.p_order_img img {
    height: 120px;
    width: 100%;
    object-fit: contain;
    background: #f7f7f7;
}
.product_order_status {
    margin: 0;
    order: 3;
}
span.order_date {
    display: inline-block;
    margin-bottom: 0;
}
.cart-section tfoot tr td:first-child {
    width: 76%;
}
.cart-section .cart-table thead th:nth-last-child(-n + 4) {
    display: table-cell;
}
.cart-section tbody tr td:nth-last-child(-n + 4) {
    display: table-cell;
}
.checkout-details {
    background: transparent;
    border: none;
    padding: 15px 0;
}
.checkout_card_list ul li {
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 20px;
    border: 1px solid #eee;
}
.container, .container-sm {
    max-width: 100%;
}
.footer-title h4, .footer-title   {
    margin: 0 !important;
}
.footer-title h4{
    padding: 12px 0px;
    position: relative;
}
.footer-title .according-menu:before {
    top: 13px;
}
.parent_qty_div .quantity-input {
    display: inline-block;
}
.thumb_slider_image {
    padding: 2px 2px;
}
.thumb_slider_image > img {
    max-height: 50px;
    height: 50px;
}
.single_product_slider {
    position: relative;
    margin-bottom: 30px;
}
.product-wrapper-grid .product .product-detail h6 {
    min-height: 1px;
}
.mobile-fix-option{
    display:none;
}
footer {
    margin-bottom: 0;
}
.logo img {
    max-width: 125px;
}
.account_head_icon p {
    display: none;
}
.account_menus li a > span.account_text {
    display: none;
}
.account_menus li {
    margin-left: 10px;
    position: relative;
}
.account_menus li:first-child {
    margin: 0;
}
.main-navbar-cart {
    margin-left: 0 !important;
}
.account_menus li a {
    font-size: 13px;
}
.logo {
    padding-right: 15px;
}
.account_menus {
    margin-right: 35px;
}
.account_menus li a i {
    font-size: 18px;
    padding-right: 0 !important;
}
.main-nav-center .toggle-nav {
    position: absolute;
    z-index: 99;
    right: 15px;
    top: -112px;
    padding: 0;
}
.account_head_icon img {
    max-height: 18px;
}
.cart_icon span.cart_value {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 10px;
}
.account_head_icon a {
    padding: 0;
}
.profileTab_uline {
    margin-bottom: 40px;
}
.profileTabContent .tab_header_vs h3 {
    font-size: 17px;
}
.profileTabContent .btn-solid {
    padding: 7px 10px;
    font-size: 12px;
}

.invoice-body table td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: normal;
    text-transform: uppercase;
    color: #212529;
    font-size: 14px;
    letter-spacing: 0.8px;
    font-weight: 600;
  }
  .invoice-body table td {
    display: block;
    padding-left: 40%;
    max-width: none;
    position: relative;
    border: 1px solid #dee2e6;
    text-align: left;
  }
  .invoice-body table th {
    display: none;
 }
 .show_mobile{
    display: block;
    background: rgba(128, 128, 128, 0.08);
}
.hide_mobile{
    display: none;
}


}
.card_grid_box.isActive {
    background: rgba(128,128,128, 0.08);
}

.policy-content h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: none;
}

.policy-content ul li {
    display: block;
    color: #777;
    font-size: 13px;
    margin-bottom: 8px;
    position: relative;
    padding-left: 20px;
}
.policy-content strong {
    color: #222;
}
.policy-content h2 {
    font-size: 20px;
    font-weight: bolder;
}

.policy-content h3 {
    color: #222222;
    font-weight: bolder;
}

.policy-content h4 {
    color: #222222;
    font-weight: bolder;
}

.policy-content p{
	font-size: 13px;
}
.policy-content ul {
    padding-left: 0px;
    margin-bottom: 1rem;
    position: relative;
}
.policy-content tbody td, .policy-content thead th {
    font-size: 13px;
}
.policy-content ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    height: 5px;
    width: 5px;
    background: #222;
    border-radius: 50%;
}
.policy-content p a {
    text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.logo-format {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}

.policy-content2{}

.policy-content2 > div > p {
    padding-left: 2%;
    padding-right: 2%;
}

.policy-content2 > div > ul {
    padding-left: 2%;
    padding-right: 2%;
}

.footer-new{
    display: flex;
}

.footer-center{
    display: flex;
    align-items: center;
}

/* Copyright Information: Created by BuyGroup */