/* Slider test*/

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.email-container{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    padding-top: 70px;
    width: 100%;
}
.main-content{
    background-image: url("../images/UserVerification/emailbg.jpg");
    background-size: cover;
    width: 600px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #cc343233;
    border-radius: 20px;
    margin: auto;
}
.email-container .main-content h1{
    margin-top: 25px;
    padding-top: 15px;
    color:#cc3432;
    margin-bottom: 0;
    background-color: #ffffff;
    text-transform: capitalize;
    font-size:36px;
    display: flex;
    align-items: baseline;
    justify-content: center;

}
.email-container .main-content h1 img{
    margin-left: 10px;
}
.email-container .main-content h4{
    font-weight: 600;
    color:#333333;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    padding-top: 5px;
    background-color: #ffffff;
    border-radius: 0 0px 20px 20px;
}
.bottom-text{
    margin: 30px;
    text-align: center;
    font-size: 18px;
    color: #5c5c5c;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}
.img-effect{
    width:100px;
   padding: 1px 0;
//    background: rgb(248 248 248 / 75%);
   border-radius:20px;
   box-shadow: 5px 10px 15px rgba(0,0,0,0.5);
   animation: mymove 2s linear infinite;
}
.animate{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
    animation: MoveUpDown 2s linear infinite;
}
@keyframes animate {
  from { top: 0; left: 0; }
  to   { top: 0px; left: 0px; }
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 30px;
  }
}
@keyframes mymove {
    0%, 100% {box-shadow:5px 10px 15px rgba(0,0,0,0.5);}
    50% {
          box-shadow: 0px 5px 10px rgba(0,0,0,0.25);
          width: 25px;
        //   background: rgb(248 248 248 / 75%);
    }
}
@media (max-width: 767px) and (min-width: 520px){
    .main-content{
        width: 500px;
    }
}
@media (max-width: 520px) and (min-width: 320px){
    .main-content{
        width: 320px;
    }
    .email-container .main-content h1{
        font-size: 26px;
    }
}
.email-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.nav-submenu{
    max-height:300px ;
    overflow-x: hidden;
    overflow-y: auto;
}

.nav-submenu::-webkit-scrollbar {
    width: 5px;
}
.nav-submenu::-webkit-scrollbar-thumb{ 
background-color:#cecece;

}
.nav-submenu::-webkit-scrollbar-thumb:hover{
background-color:#cecece;


}
.nav-submenu::-webkit-scrollbar-thumb:active{
background-color:#cecece;
border:1px solid #cecece;
}
.nav-submenu::-webkit-scrollbar-track{
-webkit-box-shadow:0 0 2px gray inset;
}
.currentGrouplevel{
    box-shadow: 0 0 10px #cdcdcd;
    color:red;
    font-weight:600
}
.UserAddressBtn{
    background: #c6313d;
    color: white;
    border-radius: 3px;
    font-size: 14px;
    padding: 10.5px;
    padding-bottom: 11px;
    border-radius: 5px;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 10px;
    margin-top: 1px;
}
.NotAvailableBox{
    display: flex;
    justify-content: center;
    height: 200px;
    align-items: center;
}
.NotAvailableBox p {
    padding: 10px;
    background: #f8f8f8;
    color:black;
}

.addressSelect{
    background: #f9f9f9;;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 3px;
}
// .addressSelect input[type=radio] {
//     width: 20px;
//     height: 20px;
//     color:#c6313d;
// }
.addressSelect input[type='radio'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border:1px solid darkgray;
    border-radius:50%;
    outline:none;
    box-shadow:0 0 5px 0px gray inset;
  }
  
  
  
  .addressSelect input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;    
    border-radius:50%;    
  }
  .addressSelect input[type='radio']:checked:before {
    background: #c6313d;;
  }
  
